import {
  dayOfMonthOptions,
  extractDayFromMdy,
  extractMonthFromMdy,
  extractYearFromMdy,
  isValidMdy,
  mdyToLocalDate,
  monthOptions,
  roundToDayEnd,
  roundToDayStart,
  toMdy,
} from '@wirechunk/lib/dates.ts';
import { componentClassName } from '@wirechunk/lib/mixer/component-class-name.ts';
import type { DateInputComponent } from '@wirechunk/lib/mixer/types/components.ts';
import { DateInputComponentStyle } from '@wirechunk/lib/mixer/types/components.ts';
import type { ValidInputComponent } from '@wirechunk/lib/mixer/utils.ts';
import { stringOrDefaultNull } from '@wirechunk/lib/strings.ts';
import { clsx } from 'clsx';
import { isDate, isNumber } from 'lodash-es';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import type { FunctionComponent } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { useInputDataContext } from '../../../contexts/InputDataContext.tsx';
import { useInputId } from '../../../hooks/use-input-id.ts';
import { InputNotice, NoticeSeverity } from '../../InputNotice/InputNotice.tsx';
import { withValidInputComponent } from '../../mixer-hocs/with-valid-input-component.tsx';
import styles from './DateInput.module.css';

type DateInput3Fields = {
  month: number | null;
  day: number | null;
  year: number | null;
};

const isValidDay = (day: number) => day >= 1 && day <= 31;
const isValidMonth = (month: number) => month >= 1 && month <= 12;
const isValidYear = (year: number) => year >= 1000 && year <= 9999;

const dateInput3FieldsToMdy = ({ month, day, year }: DateInput3Fields): string | null => {
  if (!month || !day || !year) {
    return null;
  }

  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
};

type DateInputCalendarStyleProps = {
  inputId: string;
  inputValue: string | null;
  placeholder: string | undefined;
  valid: boolean;
  required: boolean;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  onChange: (date: string | null) => void;
};

const DateInputCalendarStyle: FunctionComponent<DateInputCalendarStyleProps> = ({
  inputId,
  inputValue,
  placeholder,
  valid,
  required,
  minDate,
  maxDate,
  onChange,
}) => (
  <Calendar
    inputId={inputId}
    className={clsx('w-full', !valid && 'p-invalid')}
    panelClassName={styles.dateInputPanel}
    placeholder={placeholder || undefined}
    minDate={minDate}
    maxDate={maxDate}
    value={inputValue && isValidMdy(inputValue) ? mdyToLocalDate(inputValue) : null}
    onChange={({ value }) => {
      // Specifically check for a Date object in case we get an array of dates (for a range).
      if (isDate(value)) {
        onChange(toMdy(value));
      } else if (!required) {
        onChange(null);
      }
    }}
    todayButtonClassName="hidden"
    showButtonBar={!required}
  />
);

const MemoDateInputCalendarStyle = memo(DateInputCalendarStyle);

type DateInput3FieldsStyleProps = {
  inputId: string;
  inputValue: string | null;
  monthPlaceholder: string | undefined;
  dayPlaceholder: string | undefined;
  yearPlaceholder: string | undefined;
  valid: boolean;
  required: boolean;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  onChange: (date: string | null) => void;
};

const DateInput3FieldsStyle: FunctionComponent<DateInput3FieldsStyleProps> = ({
  inputId,
  inputValue,
  monthPlaceholder,
  dayPlaceholder,
  yearPlaceholder,
  valid,
  required,
  onChange,
}) => {
  const [month, setMonth] = useState<number | null>(() =>
    inputValue ? extractMonthFromMdy(inputValue) : null,
  );
  const [day, setDay] = useState<number | null>(() =>
    inputValue ? extractDayFromMdy(inputValue) : null,
  );
  const [year, setYear] = useState<number | null>(() =>
    inputValue ? extractYearFromMdy(inputValue) : null,
  );

  useEffect(() => {
    if (inputValue) {
      setMonth(extractMonthFromMdy(inputValue));
      setDay(extractDayFromMdy(inputValue));
      setYear(extractYearFromMdy(inputValue));
    }
  }, [inputValue]);

  return (
    <div className="flex gap-2 align-items-center">
      <Dropdown
        className={clsx('flex-grow-1', !valid && 'p-invalid')}
        inputId={inputId}
        options={monthOptions}
        value={month}
        showClear={!required}
        placeholder={monthPlaceholder || undefined}
        onChange={({ value: month }) => {
          if (isNumber(month) && isValidMonth(month)) {
            setMonth(month);
            if (isNumber(day) && isValidDay(day) && isNumber(year) && isValidYear(year)) {
              onChange(dateInput3FieldsToMdy({ month, day, year }));
            }
          } else if (month === '' || month === null) {
            setMonth(null);
            if (!day && !year) {
              onChange(null);
            }
          }
        }}
      />
      <Dropdown
        className={clsx('w-6rem', !valid && 'p-invalid')}
        value={day}
        options={dayOfMonthOptions}
        placeholder={dayPlaceholder || undefined}
        onChange={({ value: day }) => {
          if (isNumber(day) && isValidDay(day)) {
            setDay(day);
            if (isNumber(month) && isValidMonth(month) && isNumber(year) && isValidYear(year)) {
              onChange(dateInput3FieldsToMdy({ month, day, year }));
            }
          } else if (day === '' || day === null) {
            setDay(null);
            if (!month && !year) {
              onChange(null);
            }
          }
        }}
      />
      <InputNumber
        value={year}
        inputClassName={clsx('w-4rem', !valid && 'p-invalid')}
        placeholder={yearPlaceholder || undefined}
        maxLength={4}
        format={false}
        onChange={({ value: year }) => {
          if (isNumber(year) && isValidYear(year)) {
            setYear(year);
            if (isNumber(month) && isValidMonth(month) && isNumber(day) && isValidDay(day)) {
              onChange(dateInput3FieldsToMdy({ month, day, year }));
            }
          } else if ((year as number | string) === '' || year === null) {
            setYear(null);
            if (!month && !day) {
              onChange(null);
            }
          }
        }}
      />
    </div>
  );
};

export const GuardedDateInput: FunctionComponent<ValidInputComponent<DateInputComponent>> = (
  props,
) => {
  const { getValue, setValue, getValidationError } = useInputDataContext(props);
  const inputId = useInputId(props);

  const { minimumDate, maximumDate } = props;

  const minDate = useMemo<Date | undefined>(() => {
    if (minimumDate) {
      if (minimumDate.type === 'date') {
        return new Date(minimumDate.value);
      }
      return roundToDayStart(new Date());
    }
    return undefined;
  }, [minimumDate]);
  const maxDate = useMemo<Date | undefined>(() => {
    if (maximumDate) {
      if (maximumDate.type === 'date') {
        return new Date(maximumDate.value);
      }
      return roundToDayEnd(new Date());
    }
    return undefined;
  }, [maximumDate]);

  const validationError = getValidationError(props);

  const inputValue = stringOrDefaultNull(getValue(props));

  return (
    <div className={`${componentClassName(props)} ${styles.dateInputComponent}`}>
      {props.label && (
        <label className="input-label block" htmlFor={inputId}>
          {props.label}
        </label>
      )}
      {props.style === DateInputComponentStyle.Calendar ? (
        <MemoDateInputCalendarStyle
          inputId={inputId}
          inputValue={inputValue}
          placeholder={props.placeholder || undefined}
          valid={!validationError}
          required={!!props.required}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(props, date);
          }}
        />
      ) : (
        <DateInput3FieldsStyle
          inputId={inputId}
          inputValue={inputValue}
          monthPlaceholder={props.monthPlaceholder || undefined}
          dayPlaceholder={props.dayPlaceholder || undefined}
          yearPlaceholder={props.yearPlaceholder || undefined}
          valid={!validationError}
          required={!!props.required}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(props, date);
          }}
        />
      )}
      {validationError && (
        <InputNotice severity={NoticeSeverity.Error}>{validationError}</InputNotice>
      )}
    </div>
  );
};

export const DateInput = withValidInputComponent<DateInputComponent>(GuardedDateInput);
